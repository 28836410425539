import video from './pwvideo.m4v'
import videosmall from './pwvideo-small.m4v'
import './App.css';

const mediaQuery = window.matchMedia('(min-width: 768px)')

let App;

if (mediaQuery.matches) {

  App = function () {
    return (
      <div className="App">
        <video className="Video" autoPlay muted playsinline loop>
            <source src={video} type="video/mp4"/>
           </video>
      </div>
    );
  }
} else {
  App = function() {
    return (
      <div className="App">
        <video className="Video" autoPlay muted playsinline loop>
            <source src={videosmall} type="video/mp4"/>
           </video>
      </div>
    );
  }
}

export default App;
